<template>
  <div>
      <!-- Background -->
      <div class="position-absolute d-none d-lg-block top-0 right-0 -z-1" :style="{
          width: '700px',
          height: '700px',
          'clip-path': 'circle(58.2% at 60% 40%)',
      }">
          <img :src="image" alt="Imagen señora comprando medicamento. Image by zinkevych on Freepik"
              class="w-100 h-100 object-cover object-bottom-center" />
      </div>

      <!-- Content -->
      <main class="d-flex flex-column gap-2 gap-lg-20 pt-2 pt-md-5 mt-md-5">
          <!--Titulo-->
          <div class="w-100 w-lg-50">
              <h2 class="display-4 bold text-black">Encuentra tu medicamento en <span
                      class="text-primary text-nowrap">un
                      par de clics</span></h2>
              <p class="text-xl my-1">Si tienes una orden para reclamar medicamentos, busca la farmacia y los
                  medicamentos
                  abajo!</p>
          </div>

          <!-- Card con filtros -->
          <div class="d-grid bg-white grid-md-cols-3 gap-3 px-2 py-1 rounded shadow">
              <b-form-group label="Departamento">
                  <v-select label="name" class="text-lg" v-model="form.state" :options="options.states"
                      placeholder="Seleccione un departamento" />
              </b-form-group>

              <b-form-group label="Ciudad">
                  <v-select label="name" class="text-lg" v-model="form.city" :options="options.cities"
                      placeholder="Seleccione un municipio" />
              </b-form-group>

              <b-form-group label="Farmacia">
                  <v-select label="name" class="text-lg" v-model="form.customer" :options="options.customers"
                      placeholder="Seleccione una farmacia" @input="(changeEntity(form.customer))" />
              </b-form-group>

              <b-form-group label="Entidad / Eps" v-if="form.customer">
                  <v-select label="name" class="text-lg" v-model="form.entity" :options="options.entities"
                      placeholder="Seleccione una entidad" />
              </b-form-group>

              <b-form-group label="Puedes seleccionar tus medicamentos" v-if="form.entity">
                  <b-button @click="showSearch = !showSearch" variant="primary" size="lg" block>
                      Buscar Medicamento
                  </b-button>
              </b-form-group>

              <b-form-group label="La farmacia se encargará de seleccionar tus medicamentos" v-if="!showSearch">
                  <b-button @click="searchMedicine(1)" variant="success" size="lg" block>
                      Avanzar sin seleccionar
                  </b-button>
              </b-form-group>

              <b-form-group label="Medicamento" class="col-span-full" v-if="showSearch">
                  <b-input-group>
                      <b-form-input v-model="form.medicine" size="lg" class="flex-grow-1"
                          placeholder="Información del medicamento" />

                      <template #append>
                          <b-button ref="searchBtn" @click="searchMedicine(2)" :disabled="!allowSearch || isLoading"
                              class="text-lg" text="Buscar">Buscar</b-button>
                      </template>
                  </b-input-group>
              </b-form-group>
          </div>
      </main>

      <!-- Mostrar resultados -->
      <section ref="resultsSection" v-if="showResult && form.customer" class="mt-4">
          <div v-if="cumsAvailables.hits.length > 0">
              <Found />
              <CumsStockForm :cums="cumsAvailables" :client="form.customer" />
          </div>
          <div v-else-if="cumsNotAvailables.hits.length > 0">
              <FoundWithoutInv />
              <CumsForm :search="formatedForm" :notificar="true" :cums="cumsNotAvailables" />
          </div>

          <NotFound v-else />
      </section>

      <!-- Modal de loader -->
      <b-modal id="loadingModal" hide-footer centered static backdrop="static" :visible="isLoading">
          <div class="text-center">
              <b-spinner small type="grow"></b-spinner>
              <p class="mt-2">Procesando...</p>
          </div>
      </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import image from '@images/stock/she.jpg';
import CumsForm from "@miscellaneous/cums.vue";
import CumsStockForm from "@miscellaneous/cums-stok.vue";
import LoaderButton from '@components/LoaderButton.vue';
import { Found, NotFound, FoundWithoutInv } from './msg';
import {mapMutations, mapActions, mapState} from '@/store/deliveries';

export default {
  components: {
      vSelect,
      LoaderButton,
      Found,
      NotFound,
      FoundWithoutInv,
      CumsForm,
      CumsStockForm,
  },
  data() {
      return {
          image,
          options: {
              states: [],
              cities: [],
              customers: [],
              entities: [],
          },
          form: {
              state: null,
              city: null,
              customer: null,
              medicine: null,
              entity: null,
              action: true,
          },
          isLoading: false, // Estado de carga para el loader
          showResult: false,

          cumsAvailables: {
              hits: [],
          },

          cumsNotAvailables: {
              hits: [],
          },
          showSearch: false,
      }
  },
  methods: {
      ...mapMutations(['setCumStock', 'setCustomer']),
      ...mapActions(['saveInStorage']),
      changeEntity(pharmacia) {
          this.getEntities(pharmacia.id);
      },
      getStates() {
          this.$http.get(`/states?customers=true`).then((res) => {
              this.options.states = res.data;
          });
      },

      getCities(state_id) {
          this.$http.get(`/states/${state_id}/cities?customers=true`).then((res) => {
              this.options.cities = res.data;
          });
      },

      getEntities(pharmacia) {
          this.$http.get(`/entities/selects/` + pharmacia).then((res) => {
              this.options.entities = res.data.entities;
              this.form.entity = res.data.entity;
          });
      },

      getCustomers(stateId, cityId) {
          this.$http.post(`/customer/search`, { stateId, cityId })
              .then((res) => {
                  this.options.customers = res.data;
              });
      },

      async searchMedicine(type) {
          this.isLoading = true; // Mostrar loader
          this.showResult = false;

          try {
              const { data } = await this.$http.post(`/cum/search`, this.formatedForm);
              let { resultsWithInventory, cums } = data;

              this.cumsAvailables = resultsWithInventory;
              this.cumsNotAvailables = cums;

              if (type == 1) {
                  this.form.action = true;
                  localStorage.setItem('dataForm', JSON.stringify(this.form));
                  if(!this.storageCustomer.id){
                    this.setCustomer(this.form.customer);
                  }
                  this.saveInStorage();
                  this.$router.push({ name: 'deliveries' });

              }

              if (type == 2) {
                  this.showResult = true;
                  this.form.action = false;
                  localStorage.setItem('dataForm', JSON.stringify(this.form));
              }
          } catch (error) {
              console.error('Error durante la búsqueda:', error);
          } finally {
              this.isLoading = false; // Ocultar loader
              this.scrollToResults(); // Desplazarse a la sección de resultados
          }
      },
      scrollToResults() {
          this.$nextTick(() => {
              const resultsSection = this.$refs.resultsSection;
              if (resultsSection) {
                  resultsSection.scrollIntoView({ behavior: 'smooth' });
              }
          });
      }
  },
  watch: {
      "form.state": function (state) {
          if (state) {
              this.getCities(state.id);
          } else {
              this.form.cityId = null;
              this.options.cities = [];
          }
      },
      "form.city": function (city) {
          if (city) {
              const { state } = this.form;
              this.getCustomers(state.id, city.id);
          } else {
              this.form.customer = null;
              this.options.customers = [];
          }
      },
  },
  computed: {
      ...mapState({
          storageCustomer: 'customer', 
          storageStock: 'cumStock',
      }),
      allowSearch() {
          return Object.values(this.form).every(value => !!value);
      },

      formatedForm() {
          return {
              stateId: this.form.state.id,
              cityId: this.form.city.id,
              customer: this.form.customer.id,
              name: this.form.medicine,
              entity: this.form.entity,
          };
      }
      
  },
  created() {
      this.getStates();
  },
}
</script>